<script>
  // core components
  import CardTable from "components/Cards/CardTable.svelte";
  import CardWebsites from '../../components/Cards/CardWebsites.svelte'
  export let location;
</script>

<div class="flex flex-wrap mt-4">
  <div class="w-full mb-12 px-4">
    <CardWebsites />
  </div>
</div>
