<script>
  // core components
  import CardPartners from '../../components/Cards/CardPartners.svelte'
  import { get } from 'svelte/store'
  import { auth } from '../../auth'
  import { afterUpdate, beforeUpdate, onMount } from 'svelte'

  export let location;

  let level;

  beforeUpdate(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("level")) {
      level = urlParams.get('level')
    } else {
      level = get(auth).sessionLevel + 1
    }
  })

</script>

<div class="flex flex-wrap mt-4">
  <div class="w-full mb-12 px-4">
    <CardPartners level="{level}" />
  </div>
</div>
