<script>
  // core components
  import { datetimeToDateTime, formattedNumber, statusName } from '../../util'
  import { callApi } from '../../api'

  const bootstrap = "../assets/img/bootstrap.jpg";
  const angular = "../assets/img/angular.jpg";
  const sketch = "../assets/img/sketch.jpg";
  const react = "../assets/img/react.jpg";
  const vue = "../assets/img/react.jpg";

  const team1 = "../assets/img/team-1-800x800.jpg";
  const team2 = "../assets/img/team-2-800x800.jpg";
  const team3 = "../assets/img/team-3-800x800.jpg";
  const team4 = "../assets/img/team-4-470x470.png";

  // can be one of light or dark
  export let color = "light";

  export let status;
  export let deposits = [];

  export let updated;

  let error;

  function submitRequestedDeposit(id, status) {
    return async () => {
      error = null;

      try {

        const memo = document.getElementById(`grid-requested-deposit-${id}`).value

        const res = await callApi({
          path: '/admin/requested-deposit',
          method: 'post',
          data: {
            id,
            status,
            memo
          }
        })

        const {success, reason} = await res.json()
        if (!success) {
          error = reason
        }

      } catch (e) {
        error = e.message
      }

      updated()
    }
  }

</script>

{#if status === 'REQ'}
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}"
  >
    {#if error}
      <div class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-orange-500">
      <span class="text-xl inline-block mr-5 align-middle">
        <i class="fas fa-bell"></i>
      </span>
        <span class="inline-block align-middle mr-8">
        {error}
      </span>
      </div>
    {/if}
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3
            class="font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}"
          >
            입금 승인 대기중
          </h3>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
        <tr>
          <th
            class="px-6 text-right align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            입금액
          </th>
          <th
            class="px-6 text-right align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            요청포인트
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            요청시각
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            메모
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            승인 / 반려
          </th>
        </tr>
        </thead>
        <tbody>

        {#each deposits as { id, toKRW, toPOINT, createdAt, fromMemo }}
          <tr>
            <td
              class="border-t-0 px-6 text-right align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {formattedNumber(toKRW.amount)}
            </td>
            <td
              class="border-t-0 px-6 text-right align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {formattedNumber(toPOINT.amount)}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {datetimeToDateTime(createdAt)}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              <input
                id="grid-requested-deposit-{id}"
                name="memo"
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                autocomplete="nope"
                value="{fromMemo}"
              />
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              <button
                class="bg-red-400 text-white active:bg-red-500 font-bold uppercase text-base px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-4 ease-linear transition-all duration-150"
                type="button"
                on:click|preventDefault={submitRequestedDeposit(id, 'OK')}
              >
                승인
              </button>

              <button
                class="bg-red-400 text-white active:bg-red-500 font-bold uppercase text-base px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-4 ease-linear transition-all duration-150"
                type="button"
                on:click|preventDefault={submitRequestedDeposit(id, 'REJECT')}
              >
                반려
              </button>
            </td>
          </tr>
        {/each}

        </tbody>
      </table>
    </div>
  </div>
{:else if status === 'DONE'}
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3
            class="font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}"
          >
            입금처리 목록
          </h3>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
        <tr>
          <th
            class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            요청 계정
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            상태
          </th>
          <th
            class="px-6 text-right align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            입금액
          </th>
          <th
            class="px-6 text-right align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            요청포인트
          </th>
          <th
            class="px-6 text-right align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            이전포인트
          </th>
          <th
            class="px-6 text-right align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            지급포인트
          </th>
          <th
            class="px-6 text-right align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            보유포인트
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            요청시각
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            응답시각
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            메모
          </th>
        </tr>
        </thead>
        <tbody>

        {#each deposits as { toName, status, toKRW, toPOINT, createdAt, updatedAt, fromMemo }}
          <tr class="{status !== 'OK' ? 'bg-blueGray-100 text-blueGray-500' : ''}">
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {toName}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {statusName[status]}
            </td>
            <td
              class="border-t-0 px-6 text-right align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {formattedNumber(toKRW.amount)}
            </td>
            <td
              class="border-t-0 px-6 text-right align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {formattedNumber(toPOINT.amount)}
            </td>
            <td
              class="border-t-0 px-6 text-right align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {status === 'OK' && toPOINT.amount !== 0 ? formattedNumber(toPOINT.pre) : ''}
            </td>
            <td
              class="border-t-0 px-6 text-right align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {status === 'OK' && toPOINT.amount !== 0 ? formattedNumber(toPOINT.amount) : ''}
            </td>
            <td
              class="border-t-0 px-6 text-right align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {status === 'OK' && toPOINT.amount !== 0 ? formattedNumber(toPOINT.post) : ''}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {datetimeToDateTime(createdAt)}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {datetimeToDateTime(updatedAt)}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {fromMemo ?? ''}
            </td>
          </tr>
        {/each}

        </tbody>
      </table>
    </div>
  </div>
{/if}