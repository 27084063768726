<script>
  // core components
  import { datetimeToDateTime, formattedNumber, statusName } from '../../util'
  import { callApi } from '../../api'

  const bootstrap = "../assets/img/bootstrap.jpg";
  const angular = "../assets/img/angular.jpg";
  const sketch = "../assets/img/sketch.jpg";
  const react = "../assets/img/react.jpg";
  const vue = "../assets/img/react.jpg";

  const team1 = "../assets/img/team-1-800x800.jpg";
  const team2 = "../assets/img/team-2-800x800.jpg";
  const team3 = "../assets/img/team-3-800x800.jpg";
  const team4 = "../assets/img/team-4-470x470.png";

  // can be one of light or dark
  export let color = "light";

  export let status;
  export let deposits = [];

  export let updated;

  let error;

  function submitUpdateDepositRequest(id, status) {
    return async () => {
      error = null;

      try {

        const res = await callApi({
          path: '/admin/deposit',
          method: 'post',
          data: {
            id,
            status
          }
        })

        const {success, reason} = await res.json()
        if (!success) {
          error = reason
        }

      } catch (e) {
        error = e.message
      }

      updated()
    }
  }

  let depositRequestSuccess
  let depositRequestError
  
  let showDepositRequestModal

  const toggleDepositRequestModal = async () => {
    showDepositRequestModal = !showDepositRequestModal
    depositRequestSuccess = null
    depositRequestError = null
  }

  const depositRequest = async () => {
    depositRequestSuccess = null
    depositRequestError = null

    const formData = new FormData(document.getElementById('deposit-request'))

    try {
      const KRW = Number(formData.get('KRW'))
      const POINT = Number(formData.get('POINT'))
      const toMemo = formData.get('memo')

      if (!isFinite(KRW) || !isFinite(POINT)) {
        depositRequestError = '숫자를 입력해주세요'
        return
      }

      if (KRW === 0 && POINT === 0) {
        depositRequestError = '모두 0 을 입력하셨습니다'
        return
      }

      const res = await callApi({
        path: '/admin/deposit',
        method: 'put',
        data: {
          KRW: KRW,
          POINT: POINT,
          toMemo: toMemo
        }
      })

      const { success, reason } = await res.json()
      if (success) {
        depositRequestSuccess = '실행 완료되었습니다'

        setTimeout(() => {
          toggleDepositRequestModal()
          updated()
        }, 300)
      } else {
        depositRequestError = reason
      }

    } catch (e) {
      depositRequestError = e.message
    }

  }

</script>

{#if status === 'REQ'}
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}"
  >
    {#if error}
      <div class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-orange-500">
      <span class="text-xl inline-block mr-5 align-middle">
        <i class="fas fa-bell"></i>
      </span>
        <span class="inline-block align-middle mr-8">
        {error}
      </span>
      </div>
    {/if}
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3
            class="font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}"
          >
            충전 대기중
          </h3>
        </div>
        <button
          class="bg-red-400 text-white active:bg-red-500 font-bold uppercase text-base px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          on:click|preventDefault={toggleDepositRequestModal}
        >
          충전 요청하기
        </button>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
        <tr>
          <th
            class="px-6 text-right align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            입금액
          </th>
          <th
            class="px-6 text-right align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            요청포인트
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            요청시각
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            메모
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            취소
          </th>
        </tr>
        </thead>
        <tbody>

        {#each deposits as { id, toKRW, toPOINT, createdAt, toMemo }}
          <tr>
            <td
              class="border-t-0 px-6 text-right align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {formattedNumber(toKRW.amount)}
            </td>
            <td
              class="border-t-0 px-6 text-right align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {formattedNumber(toPOINT.amount)}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {datetimeToDateTime(createdAt)}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {toMemo ?? ''}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              <button
                class="bg-red-400 text-white active:bg-red-500 font-bold uppercase text-base px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-4 ease-linear transition-all duration-150"
                type="button"
                on:click|preventDefault={submitUpdateDepositRequest(id, 'CANCEL')}
              >
                취소
              </button>
            </td>
          </tr>
        {/each}

        </tbody>
      </table>
    </div>
    {#if showDepositRequestModal}
      <div
        class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
        <div class="relative w-auto my-6 mx-auto max-w-sm">
          <!--content-->
          <div
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <!--header-->
            <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 class="text-3xl font-semibold">
                충전 요청
              </h3>
              <button
                class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                on:click={toggleDepositRequestModal}>
                  <span
                    class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
              </button>
            </div>
            {#if depositRequestError}
              <div class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-orange-500">
                  <span class="text-xl inline-block mr-5 align-middle">
                    <i class="fas fa-bell"></i>
                  </span>
                <span class="inline-block align-middle mr-8">
                    {depositRequestError}
                  </span>
              </div>
            {/if}
            {#if depositRequestSuccess}
              <div class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-green-500">
                  <span class="text-xl inline-block mr-5 align-middle">
                      <i class="fas fa-bell"></i>
                  </span>
                <span class="inline-block align-middle mr-8">
                    {depositRequestSuccess}
                  </span>
              </div>
            {/if}
            <!--body-->
            <div class="relative p-6 flex-auto">
              <form id="deposit-request" on:submit|preventDefault={depositRequest}>
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="grid-deposit-request-krw"
                >
                  KRW
                </label>
                <input
                  id="grid-deposit-request-krw"
                  name="KRW"
                  type="number"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  autocomplete="nope"
                  value="0"
                />
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="grid-deposit-request-point"
                >
                  포인트
                </label>
                <input
                  id="grid-deposit-request-point"
                  name="POINT"
                  type="number"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  autocomplete="nope"
                  value="0"
                />
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="grid-deposit-request-memo"
                >
                  메모
                </label>
                <input
                  id="grid-deposit-request-memo"
                  name="memo"
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  autocomplete="nope"
                />
              </form>
            </div>
            <!--footer-->
            <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
              <button
                class="text-blueGray-800 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button" on:click={toggleDepositRequestModal}>
                닫기
              </button>
              <button
                class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button" on:click={depositRequest}>
                충전 요청 실행
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
    {/if}
  </div>
{:else if status === 'DONE'}
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3
            class="font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}"
          >
            충전 목록
          </h3>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
        <tr>
          <th
            class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            상태
          </th>
          <th
            class="px-6 text-right align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            입금액
          </th>
          <th
            class="px-6 text-right align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            요청포인트
          </th>
          <th
            class="px-6 text-right align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            이전포인트
          </th>
          <th
            class="px-6 text-right align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            지급포인트
          </th>
          <th
            class="px-6 text-right align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            보유포인트
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            요청시각
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            응답시각
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}"
          >
            메모
          </th>
        </tr>
        </thead>
        <tbody>

        {#each deposits as { id, status, toKRW, toPOINT, createdAt, updatedAt, toMemo }}
          <tr class="{status !== 'OK' ? 'bg-blueGray-100 text-blueGray-500' : ''}">
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {statusName[status]}
            </td>
            <td
              class="border-t-0 px-6 text-right align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {formattedNumber(toKRW.amount)}
            </td>
            <td
              class="border-t-0 px-6 text-right align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {formattedNumber(toPOINT.amount)}
            </td>
            <td
              class="border-t-0 px-6 text-right align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {status === 'OK' && toPOINT.amount !== 0 ? formattedNumber(toPOINT.pre) : ''}
            </td>
            <td
              class="border-t-0 px-6 text-right align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {status === 'OK' && toPOINT.amount !== 0 ? formattedNumber(toPOINT.amount) : ''}
            </td>
            <td
              class="border-t-0 px-6 text-right align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {status === 'OK' && toPOINT.amount !== 0 ? formattedNumber(toPOINT.post) : ''}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {datetimeToDateTime(createdAt)}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {datetimeToDateTime(updatedAt)}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {toMemo ?? ''}
            </td>
          </tr>
        {/each}

        </tbody>
      </table>
    </div>
  </div>
{/if}