<script>

  import { datetimeToDate, getPartnerName } from '../../util'
  import { callApi } from '../../api'
  import { auth } from '../../auth'

  let error = null;
  let partner = {
    name: null,
    password: null,
    nickname: null,
    telegram: null,
    phone: null,
    partnerLevel: null,
    rate: 0,
    memo: null,
    KRW: null,
    POINT: null,
    createdAt: null,
    lastLoginAt: null,
    lastLoginIP: null
  };

  export let partnerID;

  const loading = async (partnerID) => {
    try {
      const res = await callApi({ path: `/admin/partner?ID=${partnerID}` })

      const { success, reason, data } = await res.json()
      if (!success) {
        error = reason
      } else {
        partner = data.partner
      }
    } catch (e) {
      error = e.message
    }
  }

  $: {
    loading(partnerID)
  }

  let showWithdrawButton = false

  $: {showWithdrawButton = (partner.partnerLevel === ($auth.sessionLevel + 1))}

  let showWithdrawModel = false
  let withdrawSuccess = null
  let withdrawError = null

  const toggleWithdrawModal = async () => {
    showWithdrawModel = !showWithdrawModel
    withdrawSuccess = null
    withdrawError = null
  }

  const withdraw = async () => {
    withdrawSuccess = null
    withdrawError = null

    const formData = new FormData(document.getElementById('withdraw'))

    try {
      const KRW = Number(formData.get('KRW'))
      const POINT = Number(formData.get('POINT'))

      if (!isFinite(KRW) || !isFinite(POINT)) {
        withdrawError = '숫자를 입력해주세요'
        return
      }

      if (KRW === 0 && POINT === 0) {
        withdrawError = '모두 0 을 입력하셨습니다'
        return
      }

      const res = await callApi({
        path: '/admin/withdraw',
        method: 'put',
        data: {
          toID: partner.name,
          KRW: KRW,
          POINT: POINT
        }
      })

      const { success, reason } = await res.json()
      if (success) {
        withdrawSuccess = '실행 완료되었습니다'

        setTimeout(() => {
          toggleWithdrawModal()
          loading(partnerID)
        }, 300)
      } else {
        withdrawError = reason
      }

    } catch (e) {
      withdrawError = e.message
    }

  }

</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
>
  {#if error}
    <div class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-orange-500">
      <span class="text-xl inline-block mr-5 align-middle">
        <i class="fas fa-bell"></i>
      </span>
      <span class="inline-block align-middle mr-8">
        {error}
      </span>
    </div>
  {/if}
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-blueGray-700 text-xl font-bold">
        파트너 정보
      </h6>
      {#if showWithdrawButton}
      <button
        class="bg-red-400 text-white active:bg-red-500 font-bold uppercase text-base px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="button"
        on:click|preventDefault={toggleWithdrawModal}
      >
        지급/회수하기
      </button>
      {/if}
    </div>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
      정보
    </h6>
    <form id="partner-new" on:submit|preventDefault={null}>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="grid-username"
            >
              아이디
            </label>
            <input
              id="grid-username"
              name="ID"
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              readonly
              value="{partner.name}"
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="grid-nickname"
            >
              닉네임
            </label>
            <input
              id="grid-nickname"
              name="nickname"
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              readonly
              value="{partner.nickname}"
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="grid-telegram"
            >
              텔레그램
            </label>
            <input
              id="grid-telegram"
              name="telegram"
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              readonly
              value="{partner.telegram}"
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="grid-phone"
            >
              전화번호
            </label>
            <input
              id="grid-phone"
              name="phone"
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              readonly
              value="{partner.phone}"
            />
          </div>
        </div>

        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="grid-partner-level"
            >
              파트너 등급
            </label>
            <input
              id="grid-partner-level"
              name="partnerLevel"
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              readonly
              value="{getPartnerName(partner.partnerLevel)}"
            />
          </div>
        </div>

        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"

            >
              요율
            </label>
            <input

              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              readonly
              value="{partner.rate} %"
            />
          </div>
        </div>

        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"

            >
              보유금
            </label>
            <input

              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              readonly
              value="{new Intl.NumberFormat().format(partner.KRW)}"
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"

            >
              보유포인트
            </label>
            <input

              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              readonly
              value="{new Intl.NumberFormat().format(partner.POINT)}"
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"

            >
              등록일
            </label>
            <input

              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              readonly
              value="{datetimeToDate(partner.createdAt)}"
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"

            >
              마지막 로그인
            </label>
            <input

              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              readonly
              value="{partner.lastLoginAt ? datetimeToDate(partner.lastLoginAt) : '이력없음'}"
            />
          </div>
        </div>
        <!--         <div class="w-full lg:w-6/12 px-4"> -->
        <!--           <div class="relative w-full mb-3"> -->
        <!--             <label -->
        <!--               class="block uppercase text-blueGray-600 text-xs font-bold mb-2" -->

        <!--             > -->
        <!--               마지막 로그인 IP -->
        <!--             </label> -->
        <!--             <input -->

        <!--               type="number" -->
        <!--               class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" -->
        <!--               readonly -->
        <!--               value="{partner.lastLoginIP}" -->
        <!--             /> -->
        <!--           </div> -->
        <!--         </div> -->
      </div>

      <hr class="mt-6 border-b-1 border-blueGray-300" />

      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        메모
      </h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-12/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="grid-about-me"
            >
            </label>
            <textarea
              id="grid-about-me"
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              rows="4"
              readonly
            >{partner.memo ?? ''}</textarea>
          </div>
        </div>
      </div>
    </form>
  </div>
  {#if showWithdrawModel}
    <div
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
      <div class="relative w-auto my-6 mx-auto max-w-sm">
        <!--content-->
        <div
          class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-3xl font-semibold">
              지급 / 회수
            </h3>
            <button
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              on:click={toggleWithdrawModal}>
                  <span
                    class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
            </button>
          </div>
          {#if withdrawError}
            <div class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-orange-500">
                  <span class="text-xl inline-block mr-5 align-middle">
                    <i class="fas fa-bell"></i>
                  </span>
              <span class="inline-block align-middle mr-8">
                    {withdrawError}
                  </span>
            </div>
          {/if}
          {#if withdrawSuccess}
            <div class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-green-500">
                  <span class="text-xl inline-block mr-5 align-middle">
                      <i class="fas fa-bell"></i>
                  </span>
              <span class="inline-block align-middle mr-8">
                    {withdrawSuccess}
                  </span>
            </div>
          {/if}
          <!--body-->
          <div class="relative p-6 flex-auto">
            <form id="withdraw" on:submit|preventDefault={withdraw}>
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="grid-withdraw-krw"
              >
                KRW
              </label>
              <input
                id="grid-withdraw-krw"
                name="KRW"
                type="number"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                autocomplete="nope"
                value="0"
              />
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="grid-withdraw-point"
              >
                포인트
              </label>
              <input
                id="grid-withdraw-point"
                name="POINT"
                type="number"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                autocomplete="nope"
                value="0"
              />
            </form>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
            <button
              class="text-blueGray-800 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button" on:click={toggleWithdrawModal}>
              닫기
            </button>
            <button
              class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button" on:click={withdraw}>
              지급 / 회수 실행
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  {/if}
</div>
