<script>
  // core components
  import { onMount } from 'svelte'
  import { callApi } from '../api'
  import CardDepositRequests from '../components/Cards/CardDepositRequests.svelte'
  export let location;

  let deposits = {
    'REQ' : [],
    'DONE': []
  }

  const loading = async (status) => {
    const res = await callApi({ path: `/admin/requested-deposits?status=${status}` })

    const { success, reason, data } = await res.json()
    if (success) {
      deposits[status] = data.deposits
    }
  }

  const loadingAll = async () => {
    await Promise.allSettled([loading('REQ'), loading('DONE')])
  }

  onMount(loadingAll)
</script>

<div class="flex flex-wrap mt-4">
  <div class="w-full mb-12 px-4">
    <CardDepositRequests status="REQ" deposits="{deposits.REQ}" updated="{loadingAll}" />
    <CardDepositRequests status="DONE" deposits="{deposits.DONE}" />
  </div>
</div>
