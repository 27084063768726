<script>
  import { auth } from '../../auth'
  import { callApi } from '../../api'

  export let location;

  let error = null;
  let errors = {};

  function isRequired(value) {
    return value != null && value.trim() !== ""
  }

  const submitForm = async (event) => {
    error = null;

    const formData = new FormData(event.target)

    let error_flag = false;

    for (let field of formData) {
      const [key, value] = field;

      if (!isRequired(value)) {
        errors[key] = key + ' is required'
        error_flag = true
      } else {
        errors[key] = null
      }

    }

    if (!error_flag) {
      try {

        const res = await callApi({
          path: '/admin/login',
          method: 'post',
          data: { ID: formData.get('ID'), password: formData.get('password') }
        })

        const { success, data } = await res.json()
        if (!data) {
          error = 'Failed to Login'
          return
        }

        auth.set(data)

      } catch (e) {
        error = e.message
      }
    }

  };
</script>

<div class="container mx-auto px-4 h-full">
  <div class="flex content-center items-center justify-center h-full">
    <div class="w-full lg:w-4/12 px-4">
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
        <div class="rounded-t mb-0 px-6 py-6">
          {#if error}
            <div class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-orange-500">
                        <span class="text-xl inline-block mr-5 align-middle">
                            <i class="fas fa-bell"></i>
                        </span>
              <span class="inline-block align-middle mr-8">
                            {error}
                        </span>
            </div>
          {/if}
          <div class="text-center mb-3">
            <h6 class="text-blueGray-500 text-sm font-bold">
              Sign in
            </h6>
          </div>
        </div>
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form on:submit|preventDefault={submitForm}>
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="grid-ID"
              >
                ID
              </label>
              <input
                id="grid-ID"
                name="ID"
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="ID"
              />
              {#if errors.ID}
                <p><small style="color: red"> { errors.ID } </small></p>
              {/if}
            </div>

            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="grid-password"
              >
                Password
              </label>
              <input
                id="grid-password"
                name="password"
                type="password"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Password"
              />
              {#if errors.password}
                <p><small style="color: red"> { errors.password } </small></p>
              {/if}
            </div>

            <div class="text-center mt-6">
              <button
                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                type="submit"
              >
                Sign In
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
