<script>
  // make dynamic date to be added to footer
  let date = new Date().getFullYear();
</script>

<footer class="block py-4">
  <div class="container mx-auto px-4">
    <hr class="mb-4 border-b-1 border-blueGray-200" />
    <div class="flex flex-wrap items-center md:justify-between justify-center">
      <div class="w-full px-4">
        <div
          class="text-sm text-blueGray-500 font-semibold py-1 text-center"
        >
          Copyright © {date} FunDARK
        </div>
      </div>
    </div>
  </div>
</footer>
