<script>
  import { Link, link } from "svelte-routing";

  // core components
  import NotificationDropdown from "components/Dropdowns/NotificationDropdown.svelte";
  import UserDropdown from "components/Dropdowns/UserDropdown.svelte";
  import { auth } from '../../auth'
  import { onMount } from 'svelte'
  import { get } from 'svelte/store'
  import { formattedNumber, partnerNames } from '../../util'
  import { callApi } from '../../api'

  let collapseShow = "hidden";

  function toggleCollapseShow(classes) {
    collapseShow = classes;
  }

  let showModal = false;

  function toggleModal() {
    showModal = !showModal;
    changePasswordSuccess = null
    changePasswordError = null
  }

  const user = get(auth)

  export let location;

  const logout = () => {
    auth.set(null)
    //TODO remove in DB
  }
  let depositPendingCount = 0
  let depositRequestCount = 0
  let balance = {
    KRW: null, POINT: null
  }

  const loadingPendingCount = async () => {
    if (user.sessionLevel === 0) return;

    const res = await callApi({ path: `/admin/deposits-count?status=REQ` })

    const { success, reason, data } = await res.json()

    if (success) {
      depositPendingCount = data.count
    }
  }

  const loadingRequestCount = async () => {
    const res = await callApi({ path: `/admin/requested-deposits-count?status=REQ` })

    const { success, reason, data } = await res.json()

    if (success) {
      depositRequestCount = data.count
    }
  }

  const loadingBalance = async () => {
    const res = await callApi({ path: `/admin/balance` })

    const { success, reason, data } = await res.json()

    if (success) {
      balance = data.balance
    }
  }

  const loadingAll = async () => {
    await loadingRequestCount();
    await loadingPendingCount();
    await loadingBalance();

    setTimeout(async () => {
      await loadingAll()
    }, 10000)
  }

  $: { loadingAll() }

  let changePasswordError = null;
  let changePasswordSuccess = null;
  let errors = {};

  function isRequired(value) {
    return value != null && value.trim() !== ""
  }

  async function changePassword() {
    changePasswordError = null;
    changePasswordSuccess = null;

    const formData = new FormData(document.getElementById('change-password'))

    let error_flag = false;

    for (let field of formData) {
      const [key, value] = field;

      if (!isRequired(value)) {
        errors[key] = key + ' is required'
        error_flag = true
      } else {
        errors[key] = null
      }
    }

    if (!error_flag) {
      try {

        const res = await callApi({
          path: '/admin/me',
          method: 'post',
          data: { password: formData.get('password') }
        })

        const { success, reason } = await res.json()
        if (!success) {
          changePasswordError = reason
          return
        }

        changePasswordSuccess = '변경되었습니다'

        setTimeout(() => {
          toggleModal()
        }, 300)

      } catch (e) {
        changePasswordError = e.message
      }
    }
  }
</script>

<nav
  class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6"
>
  <div
    class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
  >
    <!-- Toggler -->
    <button
      class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
      type="button"
      on:click={() => toggleCollapseShow('bg-white m-2 py-3 px-6')}
    >
      <i class="fas fa-bars"></i>
    </button>
    <!-- Brand -->
    <a
      use:link
      class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm capitalize font-bold p-4 px-0"
      href="/"
    >
      FunDARK
    </a>
    <span></span>

    <!-- Collapse -->
    <div
      class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded {collapseShow}"
    >
      <!-- Collapse header -->
      <div
        class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
      >
        <div class="flex flex-wrap">
          <div class="w-6/12">
            <a
              use:link
              class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm capitalize font-bold p-4 px-0"
              href="/"
            >
              FunDARK
            </a>
          </div>
          <div class="w-6/12 flex justify-end">
            <button
              type="button"
              class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
              on:click={() => toggleCollapseShow('hidden')}
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- Form -->
      <form class="mt-6 mb-4 md:hidden">
        <div class="mb-3 pt-0">
          <input
            type="text"
            placeholder="Search"
            class="border-0 px-3 py-2 h-12 border border-solid border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
          />
        </div>
      </form>

      <!-- Divider -->
      <hr class="my-4 md:min-w-full" />
      <!-- Heading -->
      <div class="w-full inline-flex justify-between">
        <h6
          class="text-blueGray-500 text-xs uppercase font-bold inline-flex flex-col justify-center no-underline"
        >
          {user.ID}
        </h6>
        <a
          on:click|preventDefault={logout}
          href="/"
          class="text-xs uppercase py-3 font-bold inline-block text-blueGray-700 hover:text-blueGray-500}"
        >
          Logout
        </a>
      </div>

      <button
        class="text-blueGray-700 hover:text-blueGray-500 font-bold uppercase text-xs px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button" on:click={toggleModal}>
        비밀번호 변경
      </button>
      {#if showModal}
        <div
          class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
          <div class="relative w-auto my-6 mx-auto max-w-sm">
            <!--content-->
            <div
              class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <!--header-->
              <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h3 class="text-3xl font-semibold">
                  비밀번호 변경
                </h3>
                <button
                  class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  on:click={toggleModal}>
                  <span
                    class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              {#if changePasswordError}
                <div class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-orange-500">
                  <span class="text-xl inline-block mr-5 align-middle">
                    <i class="fas fa-bell"></i>
                  </span>
                  <span class="inline-block align-middle mr-8">
                    {changePasswordError}
                  </span>
                </div>
              {/if}
              {#if changePasswordSuccess}
                <div class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-green-500">
                  <span class="text-xl inline-block mr-5 align-middle">
                      <i class="fas fa-bell"></i>
                  </span>
                  <span class="inline-block align-middle mr-8">
                    {changePasswordSuccess}
                  </span>
                </div>
              {/if}
              <!--body-->
              <div class="relative p-6 flex-auto">
                <form id="change-password" on:submit|preventDefault={changePassword}>
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="grid-change-password"
                  >
                    새 비밀번호
                  </label>
                  <input
                    id="grid-change-password"
                    name="password"
                    type="password"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    autocomplete="nope"
                  />
                  {#if errors.password}
                    <p><small style="color: red"> { errors.password } </small></p>
                  {/if}
                </form>
              </div>
              <!--footer-->
              <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <button
                  class="text-blueGray-800 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button" on:click={toggleModal}>
                  닫기
                </button>
                <button
                  class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button" on:click={changePassword}>
                  비밀번호 저장
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
      {/if}
      <!-- Divider -->
      <hr class="my-4 md:min-w-full" />

      <div class="w-full inline-flex justify-between">
        <h6
          class="w-full text-blueGray-500 text-xs uppercase font-bold inline-flex justify-between no-underline"
        >
          <span>보유금:</span>
          <span>{balance.KRW !== null ? formattedNumber(balance.KRW) : '로딩중'}</span>
        </h6>
      </div>

      <div class="w-full inline-flex justify-between">
        <h6
          class="w-full text-blueGray-500 text-xs uppercase font-bold inline-flex justify-between no-underline"
        >
          <span>보유포인트:</span>
          <span>{balance.POINT !== null ? formattedNumber(balance.POINT) : '로딩중'}</span>
        </h6>
      </div>
      <!-- Navigation -->
      <ul class="md:flex-col md:min-w-full flex flex-col list-none">

        <li class="items-center">
          <a
            use:link
            class="inline-flex w-full justify-between items-center text-xs uppercase py-3 font-bold {location.href.indexOf('/deposit-requests') !== -1 ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}"
            href="/deposit-requests"
          >
            <span>
              <i
                class="justify-self-start fas fa-wallet {location.href.indexOf('/deposit-requests') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"></i>
              입금
            </span>

            <span class="align-middle">대기중 {depositRequestCount}</span>
          </a>
        </li>

        <li class="items-center">
          <a
            use:link
            class="inline-flex w-full justify-between items-center text-xs uppercase py-3 font-bold {location.href.indexOf('/deposits') !== -1 ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}"
            href="/deposits"
          >
            <span>
              <i
                class="justify-self-start fas fa-wallet {location.href.indexOf('/deposits') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"></i>
              충전
            </span>

            {#if user.sessionLevel > 0}
            <span class="align-middle">대기중 {depositPendingCount}</span>
            {/if}
          </a>
        </li>

<!--         <li class="items-center"> -->
<!--           <a -->
<!--             use:link -->
<!--             class="inline-flex w-full justify-between items-center text-xs uppercase py-3 font-bold {location.href.indexOf('/QnA') !== -1 ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}" -->
<!--             href="/QnAs" -->
<!--           > -->
<!--             <span> -->
<!--               <i -->
<!--                 class="justify-self-start fas fa-comment-dots {location.href.indexOf('/QnA') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"></i> -->
<!--               1:1 문의 -->
<!--             </span> -->

<!--             <span class="align-middle">신청 {depositPendingCount} 대기 {depositRequestCount}</span> -->
<!--           </a> -->
<!--         </li> -->
      </ul>

      <!-- Divider -->
      <hr class="my-4 md:min-w-full" />
      <!-- Heading -->
      <h6
        class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
      >
        파트너 관리
      </h6>

      {#if user.sessionLevel < 5}
        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          {#each partnerNames.slice(user.sessionLevel) as partnerName, i}
            <!-- Navigation -->
            <li class="items-center">
              <a
                use:link
                href='/partners?level={(user.sessionLevel+1+i)}'
                class="text-xs uppercase py-3 font-bold block {location.href.indexOf('/partners?level='+(user.sessionLevel+1+i)) !== -1 ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}"
              >
                <i
                  class="fas fa-table mr-2 text-sm {location.href.indexOf('/partners?level='+(user.sessionLevel+1+i)) !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
                ></i>
                {partnerName} 목록
              </a>
            </li>

          {/each}

          <li class="items-center">
            <a
              use:link
              href="/partner-new"
              class="text-xs uppercase py-3 font-bold block {location.href.indexOf('/partner-new') !== -1 ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}"
            >
              <i
                class="fas fa-tools mr-2 text-sm {location.href.indexOf('/partner-new') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
              ></i>
              파트너 추가
            </a>
          </li>
        </ul>
      {/if}

      {#if user.sessionLevel === 0}

        <!-- Divider -->
        <hr class="my-4 md:min-w-full" />
        <!-- Heading -->
        <h6
          class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
        >
          사이트 관리
        </h6>
        <!-- Navigation -->
        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <li class="items-center">
            <a
              use:link
              href="/websites"
              class="text-xs uppercase py-3 font-bold block {location.href.indexOf('/websites') !== -1 ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}"
            >
              <i
                class="fas fa-table mr-2 text-sm {location.href.indexOf('/websites') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
              ></i>
              사이트 목록
            </a>
          </li>

          <li class="items-center">
            <a
              use:link
              href="/website-new"
              class="text-xs uppercase py-3 font-bold block {location.href.indexOf('/website-new') !== -1 ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}"
            >
              <i
                class="fas fa-tools mr-2 text-sm {location.href.indexOf('/website-new') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
              ></i>
              사이트 추가
            </a>
          </li>

        </ul>
      {/if}

      {#if user.sessionLevel === 0}

        <!-- Divider -->
        <hr class="my-4 md:min-w-full" />
        <!-- Heading -->
        <h6
          class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
        >
          게임 관리
        </h6>
        <!-- Navigation -->
        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <li class="items-center">
            <a
              use:link
              href="/games"
              class="text-xs uppercase py-3 font-bold block {location.href.indexOf('/games') !== -1 ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}"
            >
              <i
                class="fas fa-table mr-2 text-sm {location.href.indexOf('/games') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
              ></i>
              게임 목록
            </a>
          </li>

          <li class="items-center">
            <a
              use:link
              href="/game-new"
              class="text-xs uppercase py-3 font-bold block {location.href.indexOf('/game-new') !== -1 ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}"
            >
              <i
                class="fas fa-tools mr-2 text-sm {location.href.indexOf('/game-new') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
              ></i>
              게임 추가
            </a>
          </li>

        </ul>
      {/if}

      <!-- Divider -->
      <hr class="my-4 md:min-w-full" />
      <!-- Heading -->
      <h6
        class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
      >
        유저 관리
      </h6>
      <!-- Navigation -->
      <ul class="md:flex-col md:min-w-full flex flex-col list-none">
        <li class="items-center">
          <a
            use:link
            href="/users"
            class="text-xs uppercase py-3 font-bold block {(location.href.indexOf('/user') !== -1 && location.href.indexOf('/user-') === -1) ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-table mr-2 text-sm {(location.href.indexOf('/user') !== -1 && location.href.indexOf('/user-') === -1) ? 'opacity-75' : 'text-blueGray-300'}"
            ></i>
            유저 목록
          </a>
        </li>

      </ul>

    </div>
  </div>
</nav>