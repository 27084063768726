<script>
  import { Router, Route } from "svelte-routing";

  // components for this layout
  import AdminNavbar from "components/Navbars/AdminNavbar.svelte";
  import Sidebar from "components/Sidebar/Sidebar.svelte";
  import HeaderStats from "components/Headers/HeaderStats.svelte";
  import FooterCenter from '../components/Footers/FooterCenter.svelte'

  // pages for this layout
  import Dashboard from "views/admin/Dashboard.svelte";
  import Settings from "views/admin/Settings.svelte";
  import Tables from "views/admin/Tables.svelte";
  import Maps from "views/admin/Maps.svelte";
  import NewPartner from '../views/partner/NewPartner.svelte'
  import NewWebsite from '../views/websites/NewWebsite.svelte'
  import Websites from '../views/websites/Websites.svelte'
  import Partners from '../views/partner/Partners.svelte'
  import Partner from '../views/partner/Partner.svelte'
  import NotWorking from '../views/NotWorking.svelte'
  import Deposits from '../views/Deposits.svelte'
  import DepositRequests from '../views/DepositRequests.svelte'

  export let location;
  export let admin = "";
</script>

<div>
    <Sidebar location={location} />
    <div class="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        <HeaderStats />
        <div class="px-4 md:px-10 mx-auto w-full -m-24">
            <Router>

                <Route path="deposit-requests" component="{DepositRequests}" />
                <Route path="deposits" component="{Deposits}" />

                <Route path="partners" component="{Partners}" />
                <Route path="partner" component="{Partner}" />
                <Route path="partner-new" component="{NewPartner}" />

                <Route path="websites" component="{Websites}" />
                <Route path="website-new" component="{NewWebsite}" />

<!--                 <Route path="dashboard" component="{Dashboard}" /> -->
<!--                 <Route path="settings" component="{Settings}" /> -->
<!--                 <Route path="tables" component="{Tables}" /> -->
<!--                 <Route path="maps" component="{Maps}" /> -->

                <Route path="*" component="{NotWorking}" />
            </Router>
            <FooterCenter />
        </div>
    </div>
</div>
