export const datetimeToDate = x => {
  if (!x) return ''

  const d = new Date(x)
  return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`
}

export const datetimeToDateTime = x => {
  if (!x) return ''

  const d = new Date(x)
  const date = `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`
  const time = `${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}:${d.getSeconds().toString().padStart(2, '0')}.${d.getMilliseconds().toString().padStart(2, '0')}`

  return `${date} ${time}`
}

export const partnerNames = [
  '헤드 파트너', '본사', '부본사', '총판', '매장'
]

export const getPartnerName = (level) => {
  return partnerNames[level-1] ?? ''
}

export const statusName = {
  'REQ': '대기중',
  'DONE': '완료',
  'OK': '승인',
  'CANCEL': '취소',
  'REJECT': '반려'
}

export const formattedNumber = x => {
  return new Intl.NumberFormat().format(x)
}