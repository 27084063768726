<script>

  import { callApi } from '../../api'
  import { get } from 'svelte/store'
  import { auth } from '../../auth'
  import { partnerNames } from '../../util'

  let error = null;
  let successMessage = null;
  let errors = {};

  function isRequired(value) {
    return value != null && value.trim() !== ""
  }

  const submitForm = async () => {
    successMessage = null
    error = null

    const form = document.getElementById('partner-new')
    const formData = new FormData(form)

    let error_flag = false;

    for (let field of formData) {
      const [key, value] = field;

      if (!['memo', 'telegram', 'phone'].includes(key) && !isRequired(value)) {
        errors[key] = key + ' is required'
        error_flag = true
      } else {
        errors[key] = null
      }

    }

    if (!error_flag) {
      try {
        const partnerID = formData.get('ID')

        const res = await callApi({
          path: '/admin/partner',
          method: 'put',
          data: {
            ID: formData.get('ID'),
            password: formData.get('password'),
            nickname: formData.get('nickname'),
            phone: formData.get('phone'),
            telegram: formData.get('telegram'),
            // siteURL: formData.get('siteURL'),
            // serverIP: formData.get('serverIP'),
            partnerLevel: formData.get('partnerLevel'),
            memo: formData.get('memo'),
          }
        })

        const { success, reason } = await res.json()
        if (!success) {
          error = reason
          return
        } else {
          successMessage = '새 파트너가 등록되었습니다 : ' + partnerID
        }

        formData.delete('ID')
        formData.delete('password')
        formData.delete('phone')
        formData.delete('telegram')
        // formData.delete('siteURL')
        // formData.delete('serverIP')
        formData.delete('partnerLevel')
        formData.delete('memo')

      } catch (e) {
        error = e.message
      }
    }

  };

  const user = get(auth)
  const newPartnerLevel = user.sessionLevel + 1

  const newPartnerNames = partnerNames.slice(user.sessionLevel)

</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
>
  {#if error}
    <div class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-orange-500">
      <span class="text-xl inline-block mr-5 align-middle">
        <i class="fas fa-bell"></i>
      </span>
      <span class="inline-block align-middle mr-8">
        {error}
      </span>
    </div>
  {/if}
  {#if successMessage}
    <div class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-green-500">
      <span class="text-xl inline-block mr-5 align-middle">
        <i class="fas fa-bell"></i>
      </span>
      <span class="inline-block align-middle mr-8">
        {successMessage}
      </span>
    </div>
  {/if}
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-blueGray-700 text-xl font-bold">
        파트너 추가
      </h6>
      <button
        class="bg-red-400 text-white active:bg-red-500 font-bold uppercase text-base px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
        type="button"
        on:click|preventDefault={submitForm}
      >
        등록하기
      </button>
    </div>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form id="partner-new" on:submit|preventDefault={null}>
      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        파트너 정보
      </h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="grid-username"
            >
              아이디
            </label>
            <input
              id="grid-username"
              name="ID"
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              autocomplete="nope"
            />
            {#if errors.ID}
              <p><small style="color: red"> { errors.ID } </small></p>
            {/if}
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="grid-password"
            >
              비밀번호
            </label>
            <input
              id="grid-password"
              name="password"
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              autocomplete="nope"
            />
            {#if errors.password}
              <p><small style="color: red"> { errors.password } </small></p>
            {/if}
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="grid-nickname"
            >
              닉네임
            </label>
            <input
              id="grid-nickname"
              name="nickname"
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              autocomplete="nope"
            />
            {#if errors.nickname}
              <p><small style="color: red"> { errors.nickname } </small></p>
            {/if}
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="grid-telegram"
            >
              텔레그램
            </label>
            <input
              id="grid-telegram"
              name="telegram"
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              autocomplete="nope"
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="grid-phone"
            >
              전화번호
            </label>
            <input
              id="grid-phone"
              name="phone"
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              autocomplete="nope"
            />
          </div>
        </div>
        <!--         <div class="w-full lg:w-6/12 px-4"> -->
        <!--           <div class="relative w-full mb-3"> -->
        <!--             <label -->
        <!--               class="block uppercase text-blueGray-600 text-xs font-bold mb-2" -->
        <!--               for="grid-server-ip" -->
        <!--             > -->
        <!--               IP 주소 -->
        <!--             </label> -->
        <!--             <input -->
        <!--               id="grid-server-ip" -->
        <!--               name="serverIP" -->
        <!--               type="text" -->
        <!--               class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" -->
        <!--               autocomplete="nope" -->
        <!--             /> -->
        <!--           </div> -->
        <!--         </div> -->
        <!--         <div class="w-full lg:w-6/12 px-4"> -->
        <!--           <div class="relative w-full mb-3"> -->
        <!--             <label -->
        <!--               class="block uppercase text-blueGray-600 text-xs font-bold mb-2" -->
        <!--               for="grid-site-url" -->
        <!--             > -->
        <!--               사이트 주소 -->
        <!--             </label> -->
        <!--             <input -->
        <!--               id="grid-site-url" -->
        <!--               name="siteURL" -->
        <!--               type="text" -->
        <!--               class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" -->
        <!--               autocomplete="nope" -->
        <!--             /> -->
        <!--           </div> -->
        <!--         </div> -->
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="grid-partner-level"
            >
              파트너 등급
            </label>
            <select
              id="grid-partner-level"
              name="partnerLevel"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            >
              {#each partnerNames.slice(user.sessionLevel) as newPartnerName, i }
                <option value="{user.sessionLevel+1+i}">{newPartnerName}</option>
              {/each}
            </select>
            {#if errors.partnerLevel}
              <p><small style="color: red"> { errors.partnerLevel } </small></p>
            {/if}
          </div>
        </div>
      </div>

      <hr class="mt-6 border-b-1 border-blueGray-300" />

      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        메모
      </h6>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-12/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              for="grid-about-me"
            >
            </label>
            <textarea
              id="grid-about-me"
              name="memo"
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              rows="4"
            >

            </textarea>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
