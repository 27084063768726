import { get } from 'svelte/store'
import { auth } from './auth'

export const callApi = async ({ path, method = 'get', data }) => {

  const authInfo = await get(auth)

  const host = window.location.hostname === 'localhost' ? 'http://localhost:3000' : 'https://api-fun.dark.ooo'

  return fetch(`${host}${path}`, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      ...(authInfo && { 'x-auth-token': authInfo.token })
    },
    body: data ? JSON.stringify(data) : undefined
  })
}