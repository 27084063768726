<script>
  // core components
  import CardPartner from '../../components/Cards/CardPartner.svelte'
  import { navigate } from 'svelte-routing'
  export let location;

  const urlParams = new URLSearchParams(window.location.search);
  if (!urlParams.has('ID')) {
    navigate('/partners')
  }

  const partnerID = urlParams.get('ID');

</script>

<div class="flex flex-wrap justify-center">
  <div class="w-full lg:w-8/12 px-4">
    <CardPartner partnerID="{partnerID}" />
  </div>
</div>
