<!-- App.svelte -->
<script>
  import { Router, Route } from "svelte-routing";

  // Admin Layout
  import Admin from "./layouts/Admin.svelte";
  // Auth Layout
  import Auth from "./layouts/Auth.svelte";

  // No Layout Pages
  import Index from "./views/Index.svelte";
  import Landing from "./views/Landing.svelte";
  import Profile from "./views/Profile.svelte";
  import AuthGuard from './AuthGuard.svelte'

  export let url = "";
</script>

<Router url="{url}">
  <AuthGuard>
    <div slot="authed">
      <Route path="/*" component="{Admin}" />
    </div>

    <div slot="not-authed">
      <Route path="/*" component="{Auth}" />
    </div>
  </AuthGuard>
</Router>
